import classNames from 'classnames';
import type { FC, PropsWithChildren } from 'react';

import type { BulletPointsType } from '@/services/bullet-points';
import { capitalizeFirstLetter } from '@/utils/cvGenerator';

import LoadingPlaceholder from '../LoadingPlaceholder';
import Text from '../Text';
import { bulletPointsCategoryColor } from './BulletPointHighlightSpan';
import HighlightText from './HighlightText';

type Props = {
  bulletPointsList?: BulletPointsType[];
  className?: string;
  isLoading?: boolean;
};
const BulletPointsList: FC<PropsWithChildren<Props>> = ({
  bulletPointsList = [],
  className,
  isLoading,
  children,
}) => {
  const isBulletPointsGenerated = !isLoading && bulletPointsList.length > 0;
  const renderContent = () => {
    if (isBulletPointsGenerated)
      return (
        <div className="flex w-full flex-col gap-6 p-2 md:p-6">
          {bulletPointsList.map((bullet) => (
            <div
              key={bullet.id}
              className="flex flex-col gap-4 rounded-lg border border-jb-neutral-300 p-3"
            >
              <div className="flex flex-row gap-4 text-jb-neutral-1000">
                <div className="mt-2.5 aspect-square h-2.5 rounded-full bg-jb-neutral-700" />
                <Text
                  variant="jb-body-large"
                  className="whitespace-pre-wrap !leading-8"
                >
                  <HighlightText bullet={bullet} />
                </Text>
              </div>
              <div className="flex flex-col gap-1">
                <div className="grid grid-cols-2 flex-row gap-1 md:flex">
                  {bullet.ratings.map((rating) => (
                    <div
                      key={rating.category}
                      className="rounded border-2 px-2 py-1"
                      style={{
                        borderColor: bulletPointsCategoryColor[rating.category],
                      }}
                    >
                      <Text variant="jb-body-medium">{`${capitalizeFirstLetter(
                        rating.category
                      ).replaceAll('_', ' ')} ${rating.rate}/2`}</Text>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    if (isLoading)
      return (
        <div className="flex flex-1 flex-col items-center justify-center">
          <div className="flex flex-col gap-2">
            <LoadingPlaceholder />
            <Text variant="jb-body-medium" className="text-jb-neutral-700">
              This may take a while...
            </Text>
          </div>
        </div>
      );
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Text variant="jb-body-large" className="text-jb-neutral-700">
          No bullet points generated
        </Text>
      </div>
    );
  };

  return (
    <div
      className={classNames(
        'overflow-auto flex-1 h-full flex flex-col',
        className
      )}
    >
      <div className="flex w-full flex-1 overflow-auto">{renderContent()}</div>
      <div className="w-full py-2">{children}</div>
    </div>
  );
};
export default BulletPointsList;
