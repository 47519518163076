import classNames from 'classnames';
import type { StaticImageData } from 'next/image';
import Image from 'next/image';
import type { FC, PropsWithChildren } from 'react';

import { BulletPointsCategory } from '@/services/bullet-points';

type Props = {
  staticImage?: StaticImageData;
  absoluteImageClassName?: string;
  bulletPointsCategory?: BulletPointsCategory;
};

export const bulletPointsCategoryColor = {
  [BulletPointsCategory.ACTION_VERB]: '#F5B9BB99',
  [BulletPointsCategory.METRIC]: '#FDCDAC99',
  [BulletPointsCategory.OUTCOME]: '#B3E2CD99',
  [BulletPointsCategory.STRATEGY]: '#CBD5E899',
};

const BulletPointHighlightSpan: FC<PropsWithChildren<Props>> = ({
  absoluteImageClassName,
  bulletPointsCategory,
  staticImage,
  children,
}) => {
  return (
    <span
      style={{
        backgroundColor: bulletPointsCategory
          ? bulletPointsCategoryColor[bulletPointsCategory]
          : 'transparent',
      }}
      className="relative overflow-visible rounded-lg p-1"
    >
      {children}
      {staticImage && (
        <Image
          src={staticImage}
          alt=""
          className={classNames(
            absoluteImageClassName,
            'absolute max-w-none hidden lg:flex'
          )}
        />
      )}
    </span>
  );
};

export default BulletPointHighlightSpan;
