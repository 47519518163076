import { Icon } from '@iconify/react';
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
} from '@mindpal-co/mindpal-ui';
import type { FC, PropsWithChildren } from 'react';

import { TooltipContent } from '../ui/tooltip';

const BulletPointsTooltip: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipContent className="max-w-xs rounded-2xl p-4 text-jb-neutral-1000">
          {children}
        </TooltipContent>
        <TooltipTrigger>
          <Icon icon="material-symbols-light:info-outline" fontSize={24} />
        </TooltipTrigger>
      </Tooltip>
    </TooltipProvider>
  );
};

export default BulletPointsTooltip;
