import classNames from 'classnames';
import type { FC } from 'react';

import { BulletPointsCategory } from '@/services/bullet-points';

import Text from '../Text';
import { bulletPointsCategoryColor } from './BulletPointHighlightSpan';
import BulletPointsTooltip from './BulletPointsTooltip';

export type CategoryScoreType = {
  category: BulletPointsCategory;
  categoryName: string;
  rateAvg: number;
  rateSum: number;
  maxRate: number;
  isLastItem?: boolean;
};

type CategoryScoreItemProps = CategoryScoreType & {
  isBulletPointsGenerated: boolean;
};
const CategoryScoreItem: FC<CategoryScoreItemProps> = ({
  category,
  categoryName,
  rateSum,
  rateAvg,
  maxRate,
  isBulletPointsGenerated,
  isLastItem,
}) => {
  const tooltipsByCategory = {
    [BulletPointsCategory.ACTION_VERB]: () => (
      <CategoryTooltip
        title="Action Verbs"
        description="Start your bullet point with a strong, action-oriented word like led,
          developed, or optimized. This sets the tone for impact and highlights
          your role."
      />
    ),
    [BulletPointsCategory.STRATEGY]: () => (
      <CategoryTooltip
        title="Strategy"
        description="Describe the specific methods or approaches you used to achieve your
          goal. This gives context to your work and shows your problem-solving
          skills."
      />
    ),
    [BulletPointsCategory.OUTCOME]: () => (
      <CategoryTooltip
        title="Outcome"
        description="Highlight the tangible results of your efforts. What did you accomplish?
          Focus on the success or value your actions brought to the project or
          organization."
      />
    ),

    [BulletPointsCategory.METRIC]: () => (
      <CategoryTooltip
        title="Metric"
        description="Use numbers to quantify your impact. Whether it’s percentages, dollar amounts, or time saved, metrics make your achievements concrete and impressive."
      />
    ),
  };

  const categoryColor = bulletPointsCategoryColor[category];
  return (
    <div
      className={classNames(
        'flex w-full flex-row items-center justify-between border-jb-neutral-400 px-2 py-4',
        {
          'border-b': !isLastItem,
        }
      )}
    >
      <div className="flex flex-row items-center gap-2">
        <div
          className="aspect-square h-5 rounded-full"
          style={{ backgroundColor: categoryColor }}
        />
        <Text variant="jb-body-medium" className="text-jb-neutral-700">
          {categoryName}
        </Text>
        <BulletPointsTooltip>
          <div className="flex flex-col gap-2">
            {tooltipsByCategory[category]()}
          </div>
        </BulletPointsTooltip>
      </div>
      <div className="flex flex-row items-center gap-4">
        <div className="flex flex-row gap-1">
          {Array.from({ length: 2 }).map((_, index) => (
            <div
              key={JSON.stringify(`${category} - ${index}`)}
              style={{
                backgroundColor: rateAvg > index ? categoryColor : undefined,
              }}
              className="aspect-square h-3 rounded-full bg-jb-neutral-300"
            />
          ))}
        </div>
        <Text className="font-semibold text-jb-neutral-800">
          {isBulletPointsGenerated ? `${rateSum}/${maxRate}` : '-/-'}
        </Text>
      </div>
    </div>
  );
};

export default CategoryScoreItem;

const CategoryTooltip = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <>
      <Text className="font-semibold" variant="jb-body-small">
        {title}
      </Text>
      <Text variant="jb-body-small">{description}</Text>
    </>
  );
};
