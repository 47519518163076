import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export const useWritingAnimation = ({
  interval,
  text,
  animation = 'letter',
}: {
  text: string;
  interval: number;
  animation?: 'letter' | 'word';
}) => {
  const [index, setIndex] = useState(1);
  const { ref, inView } = useInView();
  useEffect(() => {
    if (!inView) return;
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => {
        if (animation === 'word' && prevIndex >= text.split(' ').length)
          clearInterval(intervalId);
        if (animation === 'letter' && prevIndex >= text.length)
          clearInterval(intervalId);
        return prevIndex + 1;
      });
    }, interval);
    return () => clearInterval(intervalId);
  }, [inView]);

  const getAnimatedText = () => {
    if (animation === 'letter') {
      return text.slice(0, index);
    }
    if (animation === 'word') {
      return text.split(' ').slice(0, index).join(' ');
    }
  };

  return { ref, animatedText: getAnimatedText() };
};
